<template>
    <default-layout>

        <div class="d-flex flex-wrap w-100 px-sm-4" style="background-color:var(--spainhn-rojo-corporativo)">
            <div class="py-2 px-2 ms-auto">
                <a href="/clientes/reservas" class="link-light"><small>{{ $t('default.CLIENTES_RESERVAS') }}</small></a>
            </div>

            <div class="py-2 border-start px-2">
                <a href="/clientes/cambiaclave" class="link-light"><small> {{ $t('default.CLIENTES_PASSWORD_RESET') }} </small></a>
            </div>

            <div class="py-2 border-start px-2">
                <a href="/clientes/cambiaemail" class="link-light"><small> {{ $t('default.CLIENTES_EMAIL_CHANGE') }} </small></a>
            </div>
            <div class="py-2 border-start px-2">
                <a href="/clientes/editar" class="link-light"><small> {{ $t('default.CLIENTES_EDIT_PROFILE') }} </small></a>
            </div>
            <div class="py-2 border-start px-2 me-auto">
                <a href="#" class="link-light" @click="logout"><small> {{ $t('default.CLIENTES_LOGOUT') }} </small></a>
            </div>

        </div>
        <div class="container bg-white my-5 border py-5">
            
            <div class="row">
                <div class="col-12">
                    <p class="spainhn-subtitle-2-black">{{ $t( 'default.CLIENTES_EMAIL_CHANGE' ) }}</p>
                </div>
                <div class="col-12 col-lg-8 mx-auto">
                    <b-form-input class="my-1" :placeholder="$t('default.CLIENTES_NEW_EMAIL')"></b-form-input>
                    <b-form-input class="my-1" :placeholder="$t('default.CLIENTES_NEW_EMAIL_CONFIRM')"></b-form-input>
                </div>

                <div class="col-12 col-lg-8 mx-auto">
                    
                    <div class="d-flex flex-wrap justify-content-center pt-3">
                        <div class="col-12 mx-auto">
                            <div class="row mx-auto">
                                <div class="col-6 mx-auto">
                                    <mdb-btn color="indigo" class="spainhn-button mx-auto" size="lg">{{ $t('default.CLIENTES_CHANGE_PASSWORD_BUTTON') }}</mdb-btn>
                                </div>
                                <div class="col-6">
                                    <mdb-btn color="indigo" @click="() => $router.push('/clientes')" class="spainhn-button mx-auto" size="lg">{{ $t('default.CLIENTES_CANCEL_PASSWORD_BUTTON') }}</mdb-btn>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </default-layout>    
</template>

<script>
import ApiService from '../services/ApiService';
export default {
    name: "Clientes",
    components:{

    },
    data(){
        return{

        }
    },
    methods:{
        logout(){
            this.$store.dispatch('removeAccessToken')

            if(this.$route.name == 'clientes'){
                this.$router.push('/')
            }
            console.log(this.$route);
        },

    },
    async mounted(){
        console.log("Estoy aquí")
        await this.$store.dispatch('fetchAccessToken');
        let accessToken = this.$store.getters.getAccessToken;
        // console.log(accessToken);
        ApiService.validateClientToken(accessToken)
            .then( res => {
                console.log(res);
            })
            .catch( async err => {
                if(err.response.status == 401){
                    console.log(err.response.data);
                    await this.$store.dispatch('removeAccessToken');
                    this.$router.push('/clientes/login');
                }
            })
    }
}
</script>
